
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px!important;
}

p,
span{
  font-size:14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col {
  float: left;
}

.row > .col {
  margin-bottom: 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  padding: 20px;
}

.header {
  padding: 10px;
  background: #edf2f4;
  border-bottom: 1px solid #999;
}
.header a {
  color: #0072ff;
  text-decoration: none;
  margin-left: 20px;
  margin-right: 5px;
}
.header a:hover {
  color: #8a0f53;
}
.header small {
  color: #666;
}
.header .active {
  color: #2c7613;
}

.entity-field-label.name{
  font-weight: bold;
}

.filter.date-filter {
  display: inline-block!important;
  width: 100%;
}

.filter.date-filter select{
  width: 100%!important;
  float: none!important;
  margin: 0;
}

.filter.date-filter input{
  width: 100%!important;
  float: none!important;
  margin: 0!important;
}

.sidebar-dark .nav-item.active .nav-link i {
  color: #fff;
  margin-right: 10px!important;
}

.sidebar .nav-item .nav-link span{
  font-weight: 700;
}

.form-row .col{
  padding-bottom: 10px;
}

.add-button {
  margin-top: 10px;
}

.logout-button-handler button {
  background: transparent;
  font-size: 80%;
  font-weight: 400;
  color: #858796!important;
  border: 0;
  margin-top: 20px;
  text-transform: uppercase;
}

.logout-button-handler button:hover{
  background: transparent;
  border: 0!important;
  box-shadow: 0 0 0!important;
}

.logout-button-handler button:focus{
  background: transparent;
  border: 0!important;
  box-shadow: 0 0 0!important;
}

.logout-button-handler button.btn-primary:not(:disabled):not(.disabled):active{
  background: transparent;
  border: 0!important;
  box-shadow: 0 0 0!important;
}

.employee-details-handler .profile-img img{
  max-width: 220px!important;
}

.horizontal-tabs{
  margin-bottom: 30px!important;
}

.loder-custom{
  position: absolute;
  left: 50%;
  top: 30%;
}

.loder-custom img{
  position: relative;
  left: -50%;
  top: -50%;
}