.user-login-5 {
    min-height: 100vh
}

.user-login-5 .bs-reset {
    margin: 0;
    padding: 0
}

.user-login-5 .text-right {
    text-align: right
}

.user-login-5 .login-bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh
}

.user-login-5 .login-logo {
    position: absolute;
    top: 2.5em;
    left: 2.5em
}

.user-login-5 .login-logo.login-6 {
    top: 80px;
    left: 80px
}

.user-login-5 .login-container {
    position: relative;
    min-height: 100vh
}

.user-login-5 .login-container .login-copyright,
.user-login-5 .login-container .login-social,
.user-login-5 .login-container>.login-content {
    padding: 0 80px
}

.user-login-5 .login-container>.login-content {
    margin-top: 35%
}

.user-login-5 .login-container>.login-content>h1 {
    font-size: 30px;
    font-weight: 300;
    color: #4e5a64
}

.user-login-5 .login-container>.login-content p {
    color: #a0a9b4;
    font-size: 15px;
    line-height: 22px
}

.user-login-5 .login-container>.login-content>.login-form {
    margin-top: 80px;
    color: #a4aab2;
    font-size: 13px
}

.user-login-5 .login-container>.login-content>.login-form .form-control {
    width: 100%;
    padding: 10px 0;
    border: #a0a9b4;
    border-bottom: 1px solid;
    color: #868e97;
    font-size: 14px;
    margin-bottom: 30px;
    border-radius: 0!important;
    box-shadow: 0 0 0;
}

.user-login-5 .login-container>.login-content>.login-form .form-control:focus {
    outline: 0
}

.user-login-5 .login-container>.login-content>.login-form .forgot-password,
.user-login-5 .login-container>.login-content>.login-form .login-button {
    display: inline-block
}

.user-login-5 .login-container>.login-content>.login-form .rem-password {
    margin-top: 10px
}

.user-login-5 .login-container>.login-content>.login-form .rem-password>p {
    margin: 0
}

.user-login-5 .login-container>.login-content>.login-form .rem-password>.rem-checkbox {
    border-color: #a4aab2
}

.user-login-5 .login-container>.login-content>.login-form .forgot-password {
    margin-right: 1em
}

.user-login-5 .login-container>.login-content>.login-form .forgot-password>a {
    color: #a4aab2
}

.user-login-5 .login-container>.login-content>.login-form .forgot-password>a:hover {
    color: #337ab7;
    text-decoration: none
}

.user-login-5 .login-container>.login-content>.login-form .forgot-password>a:focus {
    color: #a4aab2;
    text-decoration: none
}

.user-login-5 .login-container>.login-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px
}

.user-login-5 .login-container>.login-footer .login-social {
    padding-right: 0
}

.user-login-5 .login-container>.login-footer .login-social li {
    display: inline-block;
    list-style: none;
    margin-right: 1em
}

.user-login-5 .login-container>.login-footer .login-social a {
    color: #a9b5be;
    font-size: 18px
}

.user-login-5 .login-container>.login-footer .login-social a:hover {
    color: #337ab7;
    text-decoration: none
}

.user-login-5 .login-container>.login-footer .login-social a:focus {
    color: #a9b5be
}

.user-login-5 .login-container>.login-footer .login-copyright {
    padding-left: 0;
    margin-top: 6px
}

.user-login-5 .login-container>.login-footer .login-copyright>p {
    margin: 0;
    font-size: 13px;
    color: #a9b5be
}

.user-login-5 .alert {
    margin-top: -60px
}

.user-login-5 .form-group.has-error {
    border-bottom: 2px solid #ed6b75!important
}

.user-login-5 .form-group.valid {
    border-bottom: 1px solid #a0a9b4!important
}

@media (max-width:1365px) {
    .user-login-5 .login-logo.login-6 {
        top: 40px;
        left: 40px
    }
    .user-login-5 .login-container .login-copyright,
    .user-login-5 .login-container .login-social,
    .user-login-5 .login-container>.login-content {
        padding: 0 40px
    }
    .user-login-5 .login-container .login-social {
        padding-right: 0
    }
    .user-login-5 .login-container .login-copyright {
        padding-left: 0
    }
}

@media (max-width:1023px) {
    .user-login-5,
    .user-login-5 .login-bg,
    .user-login-5 .login-container {
        min-height: 50vh
    }
    .user-login-5 .mt-login-5-bsfix {
        width: 100%
    }
    .user-login-5 .login-logo.login-6 {
        position: relative;
        margin: 0 0 40px
    }
    .user-login-5 .login-container>.login-content {
        margin-top: 60px
    }
    .user-login-5 .login-container>.login-content .login-form {
        margin-top: 40px
    }
    .user-login-5 .login-container .rem-password {
        margin-bottom: 1em
    }
    .user-login-5 .login-container>.login-footer {
        position: relative;
        margin-top: 40px;
        padding-bottom: 0
    }
    .user-login-5 .login-container>.login-footer .login-social li {
        margin-right: .5em
    }
    .user-login-5 .alert {
        margin-top: -20px
    }
}

@media (max-width:640px) {
    .user-login-5 .login-container>.login-content .text-right {
        text-align: left
    }
}


.col-xs-6{
    float: left
}

.col-xs-6{
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.col-xs-6 {
    width: 50%
}